import React, { useState } from 'react';
import './style/Contato-Style.css';


const Contato = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validateForm = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailPattern.test(email)) {
      setEmailError('Insira um e-mail válido.');
      return false;
    } else {
      setEmailError('');
    }

    if (!name.trim()) {
      setNameError('Por favor, insira seu nome.');
      return false;
    } else {
      setNameError('');
    }

    if (!phoneNumber.trim()) {
      setPhoneNumberError('Por favor, insira seu número de telefone.');
      return false;
    } else {
      setPhoneNumberError('');
    }
    return true;
  };

  const handleButtonClick = () => {
    const isValid = validateForm();

    if (isValid) {
      setSuccessMessage('Formulário enviado com sucesso!');
      clearMessages();
      // Aqui você pode enviar o formulário ou realizar outras ações, se necessário
    } else {
      clearMessages();
    }
  };

  const clearMessages = () => {
    // Define um timer para limpar as mensagens após 3 segundos (3000 milissegundos)
    setTimeout(() => {
      setEmailError('');
      setNameError('');
      setPhoneNumberError('');
      setSuccessMessage('');
    }, 3000);
  };

  // Função para formatar o campo de telefone
  const handlePhone = (event) => {
    let input = event.target;
    input.value = phoneMask(input.value);
    setPhoneNumber(input.value); // Atualiza o estado do número de telefone
  };

  const phoneMask = (value) => {
    if (!value) return '';
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '($1) $2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');
    return value;
  };

return (

<div id="content-contato">
  <img src="img/icon-circuit-1.svg" alt="icon-circuit" id="servicos-circuit"></img>
  <div id="contato">
    <div id="entre-em-contato">
      <h3 id="titulo-cont">Agende a sua consultoria gratuita <span>.</span></h3>
      <p>Teve interesse nos nossos serviços? Entre em contato agora e faça um orçamento.</p>
      <p><span><b>Preços à combinar!</b></span></p>
      <a href='https://wa.me/556281893795?text=Olá!%20Gostaria%20de%20fazer%20um%20orçamento.' rel="noreferrer" target="_blank" className="btn-simple">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path opacity="1" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
            </svg>
      </a>
    </div>
    <div id="entre-em-contato">
      <h3 id="titulo-cont">dúvidas ou reclamações <span>?</span></h3>
      <p>Envie uma mensagem no nosso e-mail</p>
      <form
        method="post"
        action="https://www.news.orbytesistemas.com/news/?p=subscribe&id=1"
        name="subscribeform"
        target="hidden_iframe"
        id="custom-form"
      >
        <input
          type="hidden"
          name="list[3]"
          value="signup"
        />
        <input
          type="hidden"
          name="listname[3]"
          value="test"
        />

        <div id="wrapper-input">
          <input
            type="text"
            name="email"
            id="email"
            className="input-text form-control"
            placeholder="Seu melhor e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="text"
            name="attribute1"
            id="attribute1"
            className="input-text"
            placeholder="Seu nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <input
            type="text"
            name="attribute2"
            id="attribute2"
            className="input-text"
            placeholder="Número para contato"
            value={phoneNumber}
            onChange={handlePhone}
            size="15"
          />
          </div>
          <input
              type="submit"
              name="subscribe"
              value="Enviar"
              onClick={handleButtonClick}/>
      </form>
      <iframe
        name="hidden_iframe"
        title="hidden_iframe"
        id="hidden_iframe"
        src="https://www.news.orbytesistemas.com/news/?p=subscribe&id=1"
      />
    </div>
  </div>
  <div id="msg-wrapper">
    <div className={`msg-error ${emailError ? 'active' : ''}`}><p2>{emailError}</p2></div>
    <div className={`msg-error ${nameError ? 'active' : ''}`}><p2>{nameError}</p2></div>
    <div className={`msg-error ${phoneNumberError ? 'active' : ''}`}><p2>{phoneNumberError}</p2></div>
    <div className={`success-message ${successMessage ? 'active' : ''}`}><p2>{successMessage}</p2></div>
  </div>
</div>

);
};

export default Contato;
import React from 'react';
import { NavLink } from 'react-router-dom';
import './style/Footer-Style.css';

const Footer = () => {
return (

<footer>
    <div id="footer-master">
        <div id="footer-social">
            <img id="logo-footer" src="img/logo-vertical.svg" alt="a"></img>
            <div>
                <a href="https://twitter.com/OrbyteSistemas" target="_blank" rel="noopener noreferrer"><img className="icon-social" src="img/social2.svg" alt=""></img></a>
                <a href="https://www.instagram.com/orbyte.sistemas/" target="_blank" rel="noopener noreferrer"><img className="icon-social" src="img/social3.svg" alt=""></img></a>
            </div>
        </div>
        <div id="footer-menu">
            <div>
                <h3>Menu<span>.</span></h3>
                <ul>
                    <li><NavLink className="footerlink" to="/"><p2>Início</p2></NavLink></li>
                    <li><NavLink className="footerlink" to="/servicos"><p2>Serviços</p2></NavLink></li>
                    <li><NavLink className="footerlink" to="/contato"><p2>Entre em contato</p2></NavLink></li>
                </ul>
            </div>
            <div>
                <h3>Serviços<span>.</span></h3>
                <ul>
                    <li><p2>Desen. Web</p2></li>
                    <li><p2>Design</p2></li>
                    <li><p2>Marketing</p2></li>
                </ul>
            </div>
        </div>
    </div>
    <div id="footer-min">
        <p3>Copyright - Orbyte Sistemas ® (2023)</p3>
    </div>
</footer>

);
};

export default Footer;
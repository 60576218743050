import React, { useEffect } from 'react';
import { NavLink , useLocation } from 'react-router-dom';
import './style/Header-Style.css';

const Header = () => {

useEffect(() => {
    const btnMenuMobile = document.getElementById('btnMenuMobile');

    function toggleMenu() {
        const navMenu = document.getElementById('navMenu');
        const btnMenu = document.getElementById('btnMenuMobile');
        navMenu.classList.toggle('active');
        btnMenu.classList.toggle('active');
    }

    if (btnMenuMobile) {
        btnMenuMobile.addEventListener('click', toggleMenu);
    }

    return () => {
        if (btnMenuMobile) {
        btnMenuMobile.removeEventListener('click', toggleMenu);
        }
    };
}, []);

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

};

return (

<header>
    <div>
        <ScrollToTop />
        <NavLink to="/"><img src="img/logo-horizontal.svg" id="nav-img-hor" alt="Logo horizontal"></img></NavLink>
        <NavLink to="/"><img src="img/logo-vertical (2).svg" id="nav-img-ver" alt="Logo vertical"></img></NavLink>
            <button id="btnMenuMobile"><img src="img/icon-hamburguer.svg" alt="menu"></img></button>
            <div id="navMenu">
                <ul>
                    <NavLink to="/" end className="nav-link">
                        <li><p>Home</p></li>
                    </NavLink>

                    <NavLink to="/servicos" className="nav-link">
                        <li><p>Serviços</p></li>
                    </NavLink>

                    <NavLink to="/contato" className="nav-link">
                        <li><p>Contato</p></li>
                    </NavLink>
                </ul>
            </div>
    </div>
</header>

);

};

export default Header;
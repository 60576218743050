import React from 'react';
import './style/Home-Style.css';
import { NavLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';



const Home = () => {

return (

<div id="home">
  <div className="part-home">
    <div className="super-content">
      <div id="content-princ">
        <div id="text-princ">
          <h3>Dê um upgrade no seu negócio com a</h3>
          <h1>ORBYTE SISTEMAS</h1>
        <ScrollLink className="btn-simple" to="pt-2" smooth={true} duration={500}>
          <h3>saiba mais</h3>
        </ScrollLink>
        </div>
        <svg viewBox="0 0 160 160" width="160" height="160">
          <circle id="paths" cx="80" cy="80" r="50" />
          <g transform=" matrix(0.866, -0.5, 0.25, 0.433, 80, 80)">
            <path d="M 0,70 A 65,70 0 0,0 65,0 5,5 0 0,1 75,0 75,70 0 0,1 0,70Z" fill="#FFF">
              <animateTransform attributeName="transform" type="rotate" from="360 0 0" to="0 0 0" dur="3s" repeatCount="indefinite" />
            </path>
          </g>
          <path id="paths" d="M 50,0 A 50,50 0 0,0 -50,0Z" transform="matrix(0.866, -0.5, 0.5, 0.866, 80, 80)" />
        </svg>
      </div>
      <div id="content-sec">
        <div id="cont-sec-1">
          <h2>+100</h2><p>Projetos executados com sucesso</p>
        </div>
        <div id="cont-sec-2">
          <h2>97%</h2><p>Clientes aprovaram os serviços</p>
        </div>
      </div>
    </div>
    <div id="wrapper-banner1">
      <img id="banner1" alt="Banner Orbyte 1" src='img\banner1-min.webp'></img>
    </div>
    <img id="fav-icon" alt="Separator icon" src='img/fav-icon.svg'></img>
    <img id="hrrounded" alt="Separator" src='img/hr-rounded.svg'></img>
  </div>

  <div className="part-home tickerContainer">
    <div className="ticker">
      <div>
        <span><h3>+100 Projetos executados com sucesso - 97% Clientes aprovaram os serviços</h3></span>
        <span><h3>+100 Projetos executados com sucesso - 97% Clientes aprovaram os serviços</h3></span>
      </div>
    </div>
  </div>

  <div className="part-home pt-2">
    <div className="super-content" id="pt-2">
      <div id="text-pt2">
        <h3>O que podemos oferecer para</h3>
        <h1>A sua empresa?</h1>
      </div>
      <div id="jobs-pt2">
        <div>
          <h2>01</h2>
          <h3>Dev. Web</h3>
          <ul>
            <li>Dev. Front-end</li>
            <li>Dev. Back-end</li>
          </ul>
        </div>
        <div>
          <h2>02</h2>
          <h3>Design</h3>
          <ul>
            <li>UI / UX Design</li>
            <li>Brand Design</li>
            <li>Social Media</li>
          </ul>
        </div>
        <div>
          <h2>03</h2>
          <h3>Marketing</h3>
          <ul>
            <li>Tráfego pago</li>
            <li>Gestão de conta</li>
            <li>Acessoria</li>
          </ul>
        </div>
        <div>
          <h2>04</h2>
          <h3>Dev. de App’s</h3>
          <ul>
            <li>Android/IOS</li>
            <li>Desktop App's</li>
            <li>Automation codes</li>
          </ul>
        </div>
      </div>
    </div>
    <div id="wrapper-banner2">
      <img id="banner2" alt="Banner Orbyte 2" src='img/banner2-min.webp'></img>
    </div>
  </div>

  <div className="part-home">
    <div id="text-pt3">
      <h3>O que nós oferecemos é</h3>
      <h2>Muito mais  que serviços</h2>
    </div>
    <div id="wrapper-pt3">
      <div className="super-content" id="pt-3">
        <div>
          <img className="icons-hab-pt3" src="img/icon-hab1.svg" alt="icon"></img>
          <h3>Trabalho confiável</h3>
          <p>Compromisso com a excelência, assegurando resultados confiáveis e consistentes.</p>
        </div>
        <div>
          <img className="icons-hab-pt3" src="img/icon-hab2.svg" alt="icon"></img>
          <h3>Cuidado</h3>
          <p>Valorizamos cada detalhe, dedicando cuidado especial a cada projeto e cliente.</p>
        </div>
        <div>
          <img className="icons-hab-pt3" src="img/icon-hab3.svg" alt="icon"></img>
          <h3>Transparência</h3>
          <p>Nossa conduta é baseada na transparência, proporcionando clareza em todas as interações e processos.</p>
        </div>
        <div>
          <img className="icons-hab-pt3" src="img/icon-hab4.svg" alt="icon"></img>
          <h3>Abordagem individual</h3>
          <p>Reconhecemos a singularidade de cada cliente, oferecendo uma abordagem adaptada às suas necessidades específicas.</p>
          
        </div>
      </div>
      <div className="banner3" alt="Banner Orbyte 3"></div>
    </div>
    
  </div>

  <div className="part-home" id="pt-4">
    <div id="text-pt4">
      <h3>Preparado para começar a ter</h3>
      <h2>Bons resultados?</h2>
      <p>Agende uma <span>CONSULTORIA GRATUITA</span> conosco para mais detalhes sobre os nossos serviços.</p>
      <p>seu tempo de empreender é <span>AGORA</span>.</p>
      <div>
        <NavLink to="/contato" className="btn-simple">
          <h3>Entre em contato</h3>
        </NavLink>
      </div>
    </div>
  </div>
</div> 

);

};

export default Home;
import React, { useEffect } from 'react';
import './style/Servicos-Style.css';
import './style/Home-Style.css';
import { NavLink } from 'react-router-dom';


const Servicos = () => {
  useEffect(() => {
    const createAccordions = () => {
      document.querySelectorAll('details').forEach((el) => {
        new Accordion(el);
      });
    };

    createAccordions();

    // Limpar instâncias antigas ao desmontar o componente
    return () => {
      document.querySelectorAll('details').forEach((el) => {
        const accordionInstance = el._accordionInstance;
        if (accordionInstance) {
          el.removeEventListener('click', accordionInstance.handleAccordionClick);
          if (accordionInstance.animation) {
            accordionInstance.animation.cancel();
          }
        }
      });
    };
  }, []); // Executar o useEffect apenas uma vez no montagem do componente

  class Accordion {
    constructor(el) {
      // Store the <details> element
      this.el = el;
      // Store the <summary> element
      this.summary = el.querySelector('summary');
      // Store the <div class="content"> element
      this.content = el.querySelector('.content');
  
      // Store the animation object (so we can cancel it if needed)
      this.animation = null;
      // Store if the element is closing
      this.isClosing = false;
      // Store if the element is expanding
      this.isExpanding = false;
      // Detect user clicks on the summary element
      this.summary.addEventListener('click', (e) => this.onClick(e));
    }
  
    onClick(e) {
      // Stop default behaviour from the browser
      e.preventDefault();
      // Add an overflow on the <details> to avoid content overflowing
      this.el.style.overflow = 'hidden';
      // Check if the element is being closed or is already closed
      if (this.isClosing || !this.el.open) {
        this.open();
      // Check if the element is being openned or is already open
      } else if (this.isExpanding || this.el.open) {
        this.shrink();
      }
    }
  
    shrink() {
      // Set the element as "being closed"
      this.isClosing = true;
      
      // Store the current height of the element
      const startHeight = `${this.el.offsetHeight}px`;
      // Calculate the height of the summary
      const endHeight = `${this.summary.offsetHeight}px`;
      
      // If there is already an animation running
      if (this.animation) {
        // Cancel the current animation
        this.animation.cancel();
      }
      
      // Start a WAAPI animation
      this.animation = this.el.animate({
        // Set the keyframes from the startHeight to endHeight
        height: [startHeight, endHeight]
      }, {
        duration: 400,
        easing: 'ease-out'
      });
      
      // When the animation is complete, call onAnimationFinish()
      this.animation.onfinish = () => this.onAnimationFinish(false);
      // If the animation is cancelled, isClosing variable is set to false
      this.animation.oncancel = () => this.isClosing = false;
    }
  
    open() {
      // Apply a fixed height on the element
      this.el.style.height = `${this.el.offsetHeight}px`;
      // Force the [open] attribute on the details element
      this.el.open = true;
      // Wait for the next frame to call the expand function
      window.requestAnimationFrame(() => this.expand());
    }
  
    expand() {
      // Set the element as "being expanding"
      this.isExpanding = true;
      // Get the current fixed height of the element
      const startHeight = `${this.el.offsetHeight}px`;
      // Calculate the open height of the element (summary height + content height)
      const endHeight = `${this.summary.offsetHeight + this.content.offsetHeight}px`;
      
      // If there is already an animation running
      if (this.animation) {
        // Cancel the current animation
        this.animation.cancel();
      }
      
      // Start a WAAPI animation
      this.animation = this.el.animate({
        // Set the keyframes from the startHeight to endHeight
        height: [startHeight, endHeight]
      }, {
        duration: 400,
        easing: 'ease-out'
      });
      // When the animation is complete, call onAnimationFinish()
      this.animation.onfinish = () => this.onAnimationFinish(true);
      // If the animation is cancelled, isExpanding variable is set to false
      this.animation.oncancel = () => this.isExpanding = false;
    }
  
    onAnimationFinish(open) {
      // Set the open attribute based on the parameter
      this.el.open = open;
      // Clear the stored animation
      this.animation = null;
      // Reset isClosing & isExpanding
      this.isClosing = false;
      this.isExpanding = false;
      // Remove the overflow hidden and the fixed height
      this.el.style.height = this.el.style.overflow = '';
    }
  }
  
  document.querySelectorAll('details').forEach((el) => {
    new Accordion(el);
  });

  return (
    <div id="servicos">
      <img src="img/icon-circuit-1.svg" alt="icon-circuit" id="servicos-circuit"></img>
      <div className="part-servicos">
        <h2>Desenvolvimento Web<span>.</span></h2>
        <details>
            <summary>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-servicos-wrapper" viewBox="0 0 512 512">
                <path opacity="1" className="icon-servicos" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM96 96H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H96c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/>
              </svg>
              <h3>Desenvolvimento Front-end</h3>
            </summary>
            <div className="content">
              <p>Desenvolvimento front-end web envolve criar a interface visível de um site, usando linguagens como HTML, CSS e JavaScript, para proporcionar uma experiência interativa e visualmente atraente aos usuários.</p>
              <p>O desenvolvimento front-end web é essencial para criar:</p>
              <ul>
                <li>Landing pages</li>
                <li>Páginas estáticas simples</li>
                <li>Blogs, E-commerces e sistemas complexos</li>
                <li>Interfaces interativas em aplicações web</li>
              </ul>
            </div>
        </details>
        <details>
            <summary>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-servicos-wrapper" viewBox="0 0 512 512">
                  <path opacity="1" className="icon-servicos" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/>
                </svg>
                <h3>Desenvolvimento Back-end</h3>
            </summary>
            <div className="content">
              <p>O desenvolvedor back-end desempenha um papel fundamental na construção e manutenção dos aspectos funcionais e lógicos de um aplicativo ou site, garantindo que a experiência do usuário seja suportada por sistemas robustos e eficientes.</p>
              <p>O desenvolvimento back-end web é essencial para criar:</p>
              <ul>
                <li>Segurança</li>
                <li>Integração com Banco de Dados</li>
                <li>APIs (Interfaces de Programação de Aplicações)</li>
                <li>Otimização de Desempenho</li>
                <li>Colaboração com Front-end e Outras Equipes</li>
              </ul>
            </div>
        </details>
      </div>
      <div className="part-servicos">
        <h2>Design<span>.</span></h2>
        <details>
            <summary>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-servicos-wrapper" viewBox="0 0 512 512"><path opacity="1" className="icon-servicos" d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 64V416H224V160H64zm384 0H288V416H448V160z"/></svg>
              <h3>UX / UI Design</h3>
            </summary>
            <div className="content">
              <p>Brand Design é uma estratégica que visa criar uma identidade visual única e coerente para uma marca, ajudando a estabelecer conexões emocionais com o público-alvo e a diferenciar a marca em um mercado competitivo.</p>
              <ul>
                <li>Comunicação Visual</li>
                <li>Reconhecimento de Marca</li>
                <li>Experiência do Usuário (UX) e Design de Interface (UI)</li>
              </ul>
            </div>
        </details>
        <details>
            <summary>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-servicos-wrapper" viewBox="0 0 448 512"><path opacity="1" className="icon-servicos" d="M96 151.4V360.6c9.7 5.6 17.8 13.7 23.4 23.4H328.6c0-.1 .1-.2 .1-.3l-4.5-7.9-32-56 0 0c-1.4 .1-2.8 .1-4.2 .1c-35.3 0-64-28.7-64-64s28.7-64 64-64c1.4 0 2.8 0 4.2 .1l0 0 32-56 4.5-7.9-.1-.3H119.4c-5.6 9.7-13.7 17.8-23.4 23.4zM384.3 352c35.2 .2 63.7 28.7 63.7 64c0 35.3-28.7 64-64 64c-23.7 0-44.4-12.9-55.4-32H119.4c-11.1 19.1-31.7 32-55.4 32c-35.3 0-64-28.7-64-64c0-23.7 12.9-44.4 32-55.4V151.4C12.9 140.4 0 119.7 0 96C0 60.7 28.7 32 64 32c23.7 0 44.4 12.9 55.4 32H328.6c11.1-19.1 31.7-32 55.4-32c35.3 0 64 28.7 64 64c0 35.3-28.5 63.8-63.7 64l-4.5 7.9-32 56-2.3 4c4.2 8.5 6.5 18 6.5 28.1s-2.3 19.6-6.5 28.1l2.3 4 32 56 4.5 7.9z"/></svg>
              <h3>Brand Design</h3>
            </summary>
            <div className="content">
              <p>Brand Design é uma estratégica que visa criar uma identidade visual única e coerente para uma marca, ajudando a estabelecer conexões emocionais com o público-alvo e a diferenciar a marca em um mercado competitivo.</p>
              <ul>
                <li>Comunicação Visual</li>
                <li>Reconhecimento de Marca</li>
                <li>Logotipo e Identidade Visual</li>
              </ul>
            </div>
        </details>
        <details>
            <summary>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-servicos-wrapper" viewBox="0 0 640 512"><path opacity="1" className="icon-servicos" d="M256 0H576c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64zM476 106.7C471.5 100 464 96 456 96s-15.5 4-20 10.7l-56 84L362.7 169c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H552c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144zM336 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM64 128h96V384v32c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V384H512v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64zm8 64c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V312c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H72zm336 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H424c-8.8 0-16 7.2-16 16z"/></svg>
              <h3>Social Media Design</h3>
            </summary>
            <div className="content">
              <p>O Social Media Design é uma parte fundamental da estratégia de marketing digital, focada na criação de conteúdo visualmente impactante e envolvente para plataformas de mídia social. Este design desempenha um papel crucial na construção da presença online de uma marca e na interação com o público.</p>
              <ul>
                <li>Postagens Visuais</li>
                <li>Consistência Visual</li>
                <li>Criação de Conteúdo Engajador</li>
              </ul>
            </div>
        </details>
      </div>
      <div className="part-servicos">
        <h2>Marketing<span>.</span></h2>
        <details>
            <summary>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-servicos-wrapper" viewBox="0 0 512 512"><path opacity="1" className="icon-servicos" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm50.7-186.9L162.4 380.6c-19.4 7.5-38.5-11.6-31-31l55.5-144.3c3.3-8.5 9.9-15.1 18.4-18.4l144.3-55.5c19.4-7.5 38.5 11.6 31 31L325.1 306.7c-3.2 8.5-9.9 15.1-18.4 18.4zM288 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
              <h3>Tráfego Pago</h3>
            </summary>
            <div className="content">
              <p>O serviço de tráfego pago envolve a aquisição de visitantes para um site ou postagem por meio de anúncios pagos, como os veiculados em plataformas de mídia digital. Esse método é utilizado para impulsionar a visibilidade online, atrair clientes e aumentar o alcance da marca.</p>
              <ul>
                <li>Facebook</li>
                <li>Instagram</li>
                <li>Google ADS</li>
              </ul>
            </div>
        </details>
        <details>
            <summary>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-servicos-wrapper" viewBox="0 0 512 512"><path opacity="1" className="icon-servicos" d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"/></svg>
              <h3>Gerenciamento de contas</h3>
            </summary>
            <div className="content">
              <p>O gerenciamento de contas pode abranger vários contextos, desde o gerenciamento de processos de um e-commerce até contas de redes sociais. É crucial para garantir eficiência e uma experiência positiva para o usuário e para o público.</p>
              <ul>
                <li>Facebook</li>
                <li>Instagram</li>
                <li>Entre outras redes sociais</li>
              </ul>
            </div>
        </details>
        <details>
            <summary>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-servicos-wrapper" viewBox="0 0 448 512"><path opacity="1" className="icon-servicos" d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"/></svg>
              <h3>Assessoria de marketing</h3>
            </summary>
            <div className="content">
              <p>O gerenciamento de contas pode abranger vários contextos, desde o gerenciamento de processos de um e-commerce até contas de redes sociais. É crucial para garantir eficiência e uma experiência positiva para o usuário e para o público.</p>
              <ul>
                <li>Facebook</li>
                <li>Instagram</li>
                <li>Entre outras redes sociais</li>
              </ul>
            </div>
        </details>
      </div>
      <div className="part-servicos">
        <h2>Desenvolvimento de App’s<span>.</span></h2>
        <details>
            <summary>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-servicos-wrapper" viewBox="0 0 384 512"><path opacity="1" className="icon-servicos" d="M16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM144 448c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H160c-8.8 0-16 7.2-16 16zM304 64H80V384H304V64z"/></svg>
              <h3>Android / IOS App’s</h3>
            </summary>
            <div className="content">
              <p>O serviço de desenvolvimento de aplicativos para Android e iOS envolve a criação de software personalizado para dispositivos móveis. Desenvolvedores utilizam linguagens de programação específicas para criar apps que atendam às necessidades do cliente, oferecendo funcionalidades inovadoras e uma experiência de usuário otimizada nas plataformas Android e iOS.</p>
              <ul>
                <li>Swift</li>
                <li>Kotlin</li>
                <li>Java App</li>
              </ul>
            </div>
        </details>
        <details>
            <summary>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-servicos-wrapper" viewBox="0 0 640 512"><path opacity="1" className="icon-servicos" d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z"/></svg>
              <h3>Automação web e nativa</h3>
            </summary>
            <div className="content">
              <p>A automação web e nativa referem-se a diferentes abordagens para automatizar tarefas em ambientes online (web) ou em aplicativos locais instalados em um dispositivo (nativos).</p>
              <ul>
                <li>Power Automate</li>
                <li>Python automation</li>
                <li>Bots de respostas</li>
              </ul>
            </div>
        </details>
      </div>
      <div className="part-home" id="pt-4">
        <div id="text-pt4">
          <h3>E ai, preparado para começar a ter</h3>
          <h2>Bons resultados?</h2>
          <p>Agende uma <span>CONSULTORIA GRATUITA</span> com o nosso time para discutir mais detalhes sobre os nossos serviços.</p>
          <p>seu tempo de empreender é <span>AGORA</span>.</p>
          <div>
            <NavLink to="/contato" className="btn-simple">
              <h3>Entre em contato</h3>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicos;
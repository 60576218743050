import React from 'react';
import Header from './C1-Header';
import Footer from './C2-Footer';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './P1-Home';
import Servicos from './P2-Servicos';
import Contato from './P3-Contato';



const App = () => {
  
  return (
    <Router>
      <Header />
      <div id="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/servicos" element={<Servicos />} />
          <Route path="/contato" element={<Contato />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};
export default App;